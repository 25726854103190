import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Box, Button } from '@core';
import {
  ArrowLink,
  CardList,
  LinkWrapper,
  SectionContainerSC as SectionContainer,
} from '@components';
import { isLTR } from '@shared/translation-helpers';

const ShapeWrapper = styled.div`
  & > svg,
  & > img {
    position: absolute;
    height: 100%;
    overflow: hidden;
    z-index: -1;
    top: 0;
    left: 50%;
    transform: ${({ language }) => `translateX(-50%) ${isLTR(language) ? '' : 'scaleX(-1)'}`};
  }
`;

const CardsBlockSection = ({
  title,
  description,
  sectionTextLink,
  items,
  itemsOnDesktop,
  itemsOnTablet,
  itemsOnMobile,
  itemComponent: Item,
  backgroundShape: Shape,
  button,
  link,
  footer,
  sectionProps,
  withSlider,
  sliderOnDesktop,
  sliderProps,
}) => {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <SectionContainer
      data-section-id="CardsBlock"
      title={title}
      description={description}
      link={sectionTextLink}
      containerProps={{ position: 'relative', overflowX: 'hidden' }}
      footer={footer}
      {...sectionProps}
    >
      {Shape && (
        <ShapeWrapper language={language}>
          <Shape />
        </ShapeWrapper>
      )}

      <Box px={{ _: 16, md: 40 }}>
        <CardList
          items={items}
          itemComponent={Item}
          itemsOnDesktop={itemsOnDesktop}
          itemsOnTablet={itemsOnTablet}
          itemsOnMobile={itemsOnMobile}
          withSlider={withSlider}
          sliderProps={sliderProps}
          sliderOnDesktop={sliderOnDesktop}
        />
      </Box>

      {button && (
        <Box mt={{ _: 40, md: 80 }} display="flex" justifyContent="center">
          <Box minWidth={300}>
            <LinkWrapper link={button.link} target="_blank" withParams>
              <Button {...button.buttonProps} size="large" fullWidth>
                {button.title}
              </Button>
            </LinkWrapper>
          </Box>
        </Box>
      )}
      {link?.link && link?.title && (
        <Box mt={8} display="flex" justifyContent="center">
          <ArrowLink link={link.link} customEvent={link?.customEvent}>
            {link.title}
          </ArrowLink>
        </Box>
      )}
    </SectionContainer>
  );
};

export default CardsBlockSection;
